import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let FilterDrawer = class FilterDrawer extends Vue {
};
FilterDrawer = __decorate([
    Component({
        name: 'FilterDrawer',
    })
], FilterDrawer);
export default FilterDrawer;
